$loadingBackground: #efefef;
$loadingColor: #a4d5ff;
$backgroundColor: #789ebf;

.container-loading {
  width: 100vw;
  height: 100vh;
  background-color: $backgroundColor;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1000;
  padding: 32px;
}

.loading-inner {
  position: relative;
  width: 130px;
  height: 130px;
}

.loading-indicator {
  animation: rotateFull 9s 0s infinite linear forwards;
  width: 130px;
  height: 130px;
  position: relative;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

svg {
  animation: scaleUp .5s 0s 1 ease-in-out forwards;

  circle {
    position: relative;
    z-index: 9;
    stroke: $loadingBackground;
    fill: none;

    &:last-of-type {
      position: relative;
      z-index: 1;
      opacity: 1;
      stroke: $loadingColor;
      //animation-fill-mode: forwards;
      transition: stroke-dashoffset .5s ease-out;
    }
  }

  .complete & {
    animation-name: scaleDown;
  }
}

.loading-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  color: $loadingColor;
  font-size: 28px;

  .complete & {
    animation: scaleDown .5s 0s 1 ease-in-out forwards;
  }
}


@keyframes rotateFull {
  0% {
    transform: translate3d(-50%, 0, 0) rotate(0);
  }
  20% {
    transform: translate3d(-50%, 0, 0) rotate(72deg);
  }
  40% {
    transform: translate3d(-50%, 0, 0) rotate(144deg);
  }
  60% {
    transform: translate3d(-50%, 0, 0) rotate(216deg);
  }
  80% {
    transform: translate3d(-50%, 0, 0) rotate(288deg);
  }
  99%, 100% {
    transform: translate3d(-50%, 0, 0) rotate(360deg);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(.1);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  72% {
    transform: scale(1.1);
  }
  84% {
    transform: scale(.95);
  }
  98% {
    transform: scale(1);
  }
  99% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  15% {
    transform: scale(1.2);
  }
  60% {
    opacity: 1;
  }
  99%, 100% {
    transform: scale(.1);
    opacity: 0;
  }
}